import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import "../scss/termsofuse.scss";
import pdfPrivacyThailand from "../../static/files/Tyson-Online-Privacy-Notice-Supplement-Thailand-(03.10.22).pdf";

const PrivacyPolicy = () => {
    return (
        <Layout title="Privacy Policy" description="This Privacy Notice applies to the websites, apps, platforms where it appears, as well as any other points of contact with the Company.">
            <div className="privacyContent">
                <div className="termsBg">
                    <h1>Privacy Policy</h1>
                </div>
                <div className="privacy">
                    <p>
                        Maintaining the security of your data is a priority for Tyson Foods, Inc. and its subsidiaries, affiliates and related entities (collectively, “
                        <strong>Tyson</strong>,” “<strong>we</strong>” or “<strong>us</strong>”). Tyson is dedicated to being transparent about what data we collect about you and
                        how we use it. This Online Privacy Notice applies to all users of our websites, applications, platforms and features.
                        <br />
                        <br />
                        This Online Privacy Notice applies to Tyson’s information practices for you to understand how personal data is collected, used, stored and disclosed. This
                        Online Privacy Notice also describes your rights regarding the personal data we hold about you, including <a href="#privacy-rights">
                            how you can exercise those rights.
                        </a>{" "}
                        <br />
                        <br />
                        Under certain privacy laws, the “<strong>Controller</strong>” or “<strong>Personal Information Processor</strong>” of your data is Tyson Foods, Inc. We will
                        only process your personal data according to this Privacy Notice unless otherwise required by applicable law. We take steps to ensure that the personal data
                        that we collect about you is adequate, relevant, not excessive and processed for limited purposes. You may want to know more about certain types of
                        processing, so within this Privacy Notice you will find some supplementary notice information that provides additional detail about some of our activities.
                        We recommend that the supplemental information is read in parallel with this Privacy Notice. <br />
                        <br />
                        We are committed to protecting the privacy of minors. To comply with various privacy regulations, at this time, this website is not intended to be used by
                        users under the age of 18 and is not designed to attract children under the age of 18. Anyone under the age of 18 using this website should only do so with
                        participation from a parent or other guardian. If a parent or guardian becomes aware that his or her child has provided us with information without their
                        consent, he or she should contact us at <a href="mailto:privacy@tyson.com">privacy@tyson.com</a>. Tyson will delete such information within a reasonable
                        time.
                        <br />
                        <br />
                        This Privacy Notice may change from time to time. You should review this Privacy Notice periodically. You can see the date our notices were last updated at
                        the bottom of each page. <br />
                        <br />
                    </p>

                    <h5>What Data is Collected</h5>

                    <p>
                        Tyson only processes your personal data only as the law allows or requires or according to this Privacy Notice. <br />
                        <br />
                        We may collect the following categories of personal information. Not all categories may be collected about every individual:
                        <ul>
                            <li>
                                <u>Personal Identifiers</u>: such as name, phone, email address, postal address
                            </li>
                            <li>
                                <u>Demographic Information</u>: such as race or ethnic origin, gender, marital status, date of birth, age
                            </li>
                            <li>
                                <u>Information you may submit to us, such as in an online job application or information you provide when you contact us, including</u>:
                                <ul>
                                    <li>
                                        <u>Government Identifiers</u>: such as driver’s license numbers, visa information, state, province or national identification numbers, visa
                                        information or social security numbers, passport information
                                    </li>
                                    <li>
                                        <u>Financial Information</u>: such as bank account or credit card information or tax information
                                    </li>
                                    <li>
                                        <u>Employment Information</u>: such as experience and job history, proof of work eligibility, compensation history, drug screening,
                                        personnel numbers, date of employment and/or termination or resignation, salary or wage information, payroll information, business address,
                                        location, policy review verification, training attendance, attendance, vehicle and company assets, benefits application forms, performance
                                        evaluation, disciplinary information, insurance beneficiary information (including names and birthdates of children), communication in the
                                        course of employment, other information provided by you in your resume or curriculum vitae, witness statements, Device Information,
                                        Education Information, Security Information and Health Information
                                    </li>
                                    <li>
                                        <u>Education Information</u>: such as degree, school information, professional memberships and licenses as associated numbers
                                    </li>
                                    <li>
                                        <u>Health Information</u>: such as disability determinations, results of health testing or other checks, ad hoc occupational health reports,
                                        health insurance identification numbers, identification of respiratory symptoms or COVID-19 diagnosis for you or your household, mandatory
                                        self-isolating, color blindness information
                                    </li>
                                    <li>
                                        <u>Biometric Information</u>: such as body temperature checks, facial recognition or fingerprints
                                    </li>
                                    <li>
                                        <u>Security Information</u>: such as criminal history or background, signatures, vehicle license plate or other identification, photographs,
                                        CCTV footage and/or photographs, purpose of any site visit
                                    </li>
                                    <li>
                                        <u>Travel Information</u>: such as travel history (including confirmation of travel for a 14 day look back, location of travel destinations
                                        and identification of airports you or your household may have traveled through)
                                    </li>
                                    <li>
                                        <u>Device Information</u>: such as email, phone, information in relation to the use of our IT assets, including metadata and back up
                                        information
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <u>Website Information</u>: If you use our website, we may collect information about the browser you’re using and how often you visit or use our
                                site. We may see from what site you came from or what site you visit when you leave us. If you use our mobile application, we may collect your GPS
                                location. We might look at how often you use the application and from where you downloaded it. We collect this information using the tracking tools
                                described in our <Link href="/cookiespolicy">cookies policy</Link>.
                            </li>
                        </ul>
                    </p>

                    <h5>How Your Data is Collected</h5>

                    <p>
                        We may collect any information you or a member of your household voluntarily share with us directly, such as if you sign up to receive marketing
                        communications or offers from us, apply for a job or otherwise contact us online or enter a contest. We also use tracking tools like browser cookies and web
                        beacons to collect information from you. We collect information about users over time when you use this website. We may have third parties collect personal
                        information this way. To find out more, please see our <a href="/cookiespolicy">Cookies Policy</a>.
                    </p>

                    <h5>How We Use Your Data</h5>

                    <p>
                        We will use your information for the following purposes:
                        <ul>
                            <li>Conducting recruiting and evaluating eligibility of job applicants and making hiring decisions</li>
                            <li>
                                Implementing hiring decisions and managing work-related aspects of employee-employer relationship (i.e., training, payroll, benefits, travel,
                                reimbursement expenses, succession planning, employee health and safety, compliance, attendance monitoring and performance appraisals and reviews)
                            </li>
                            <li>Supporting internal administration with our affiliated entities</li>
                            <li>
                                Supporting internal operational and/or business purposes, research and development, internal operations, projections, analytics, auditing and
                                monitoring, detecting security incidents, improving our services, understanding our employee retention and attrition, quality control, identifying
                                areas for operational improvement, updating our operational and technical functionality and complying with our legal obligations
                            </li>
                            <li>Evaluating strategic decisions and opportunities</li>
                            <li>Responding to individual contacts, inquiries or complaints</li>
                            <li>
                                Providing third parties with statistical or anonymized information about Tyson employees (but those third parties will not be able to identify any
                                individual user from that information)
                            </li>
                            <li>
                                Providing our third-party service providers, suppliers or subcontractors with information about Tyson employees for such service providers,
                                suppliers or subcontractors to administer certain programs, benefits or administrative functions on behalf of Tyson or to market or advertise
                                products to provide Tyson with better service, improved offerings or special promotions or pushing notifications on our applications, such as
                                requesting feedback or distributing coupons or other offers
                            </li>
                            <li>
                                Protecting the security and integrity of our business, processes and systems (including preventing unauthorized access to our computer and
                                electronic communications systems, preventing malicious software distribution)
                            </li>
                            <li>Investigating any suspected security breaches</li>
                            <li>Fulfilling contractual obligations with our business partners and our legal obligations</li>
                            <li>
                                Defending, managing or processing legal claims and complying with a judicial proceeding, court order other legal or regulatory obligation or process
                            </li>
                            <li>Asserting our legal rights or protecting our property and assets</li>
                            <li>Preventing, investigating and detecting crime or fraud (including working with law enforcement agencies)</li>
                            <li>Protecting the safety and wellbeing of employees and visitors to our sites</li>
                            <li>
                                Transferring of our assets, properties or ownership in connection with a proposed or consummated business reorganization such as a merger,
                                acquisition or other disposition of business units
                            </li>
                        </ul>
                    </p>

                    <h5>Our Legal Basis to Use Your Data</h5>

                    <p>
                        In certain countries, we must have a legal basis to process your personal data. Where consent is a legal basis, we will give you the opportunity to decide
                        as to whether you would like to grant your consent for the processing of your personal data by us for different or new purposes. To understand our legal
                        basis and learn more information on how we use your data, please click:
                        <ul>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF AUSTRALIA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF BRAZIL
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF CALIFORNIA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF CHINA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF THE EUROPEAN UNION OR THE UNITED KINGDOM
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF THAILAND
                            </li>
                        </ul>
                    </p>

                    <h5>With Whom Your Data is Disclosed or Shared</h5>

                    <p>
                        We may disclose your personal data to the following for the purposes set out in this Privacy Notice:
                        <ul>
                            <li>Other members of our group of companies (including those outside your home jurisdiction)</li>
                            <li>Any of our employees, officers, contractors</li>
                            <li>
                                Any of our third-party insurers, professional advisers, agents, customers, third party service providers, suppliers, partners, financial partners or
                                investors or subcontractors or their representatives authorized on their behalf
                            </li>
                            <li>
                                Other organizations representing or acting on behalf of individuals who may request personal data to support a claim in relation to an incident or
                                accident involving the individual
                            </li>
                            <li>
                                Government agencies, authorities, regulators and law enforcement authorized to request personal data for lawful purposes or as otherwise required by
                                law
                            </li>
                            <li>As necessary to protect the rights and property of Tyson</li>
                            <li>As necessary during emergency situations or where necessary to protect the safety of persons</li>
                            <li>Where the personal data is publicly available</li>
                            <li>For additional purposes with your consent where such consent is required by applicable law</li>
                        </ul>
                        We will only share and transfer your personal data for specific and definite purposes pursuant to the principles of lawfulness, fairness, necessity and good
                        faith, and only share and transfer the personal data to the extent necessary for the specific purposes you are informed of. If we share your personal data
                        with recipients, we may use encryption, anonymization and other means as necessary and appropriate to ensure your personal data security. Before sharing or
                        transferring your personal data, we will follow and adopt the applicable process and requirements required by the applicable law in respect of transfer of
                        your personal data.
                        <br />
                        <br />
                        You understand and agree that we shall have the right to change our service providers and vendors at our sole discretion due to business development need
                        and reasons such as changes in service and content providers. To the extent required by applicable law, we will, before sharing your personal data with such
                        recipient, notify you of the name and contact details of the recipients, the purposes and means of processing and provision of your personal data, and the
                        types of personal data to be provided and shared, and obtain your consent to the sharing of your personal data.
                        <br />
                        <br />
                        If Tyson, or any of its subsidiary or affiliate, is sold or disposed of as a going concern, whether by merger, reorganization, sale of assets or otherwise,
                        or in the event of insolvency, bankruptcy or receivership, any and all personally identifiable information, including your account information may be one of
                        the assets sold or merged in connection with that transaction. Information about you may also need to be disclosed in connection with a commercial
                        transaction where Tyson is seeking financing, investment, support or funding. In such transaction, personal data will be subject to the promises made in any
                        pre-existing privacy policy in effect when the information was obtained.
                    </p>

                    <h5>Transfers of Your Data Outside of Your Home Country</h5>

                    <p>
                        Your personal data may be processed by Tyson, its affiliates and other third parties described herein in countries other than the country of which you are a
                        resident. Personal data may be sent to countries with different privacy laws than the country of your residence, including the United States. Because the
                        law in some of these countries does not provide the same level of protection, we implement appropriate safeguards and take measures to ensure adequate
                        mechanisms are in place to protect your personal data in accordance with applicable data protection and privacy laws. We have data transfer agreements in
                        place, which are considered appropriate safeguards implementing the Standard Contractual Clauses as approved by the European Commission to secure the
                        transfer of your personal data to other jurisdictions. You can receive further information about the data transfer agreement by referencing the contact
                        information below.
                    </p>

                    <h5>How Your Data is Protected</h5>

                    <p>
                        We have implemented appropriate physical, technical, and organizational security measures designed to secure your personal data against accidental loss and
                        unauthorized access, use, alteration, or disclosure. We also limit access to personal data to those employees, agents, contractors, and other third parties
                        that have a legitimate business need for such access.
                    </p>

                    <h5>How Long Your Data Is Kept</h5>

                    <p>
                        Except as required by applicable law or regulation or as needed in connection with legal action or an investigation, we will only keep your personal data
                        for as long as necessary to fulfill the purposes we collected it for, as required to satisfy any legal, accounting, or reporting obligations, or as
                        necessary to resolve disputes. To determine the appropriate retention period for personal data, we consider applicable legal requirements, the amount,
                        nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes we process
                        your personal data for, and whether we can achieve those purposes through other means. We specify the retention periods for your personal data in our data
                        retention policy.
                        <br />
                        <br />
                        Under some circumstances we may anonymize your personal data so that it can no longer be associated with you. We reserve the right to use such anonymous and
                        de-identified data for any legitimate business purpose without further notice to you or your consent. Once you are no longer an employee of the company, we
                        will retain and securely destroy your personal data in accordance with our data retention policy and applicable laws and regulations.
                    </p>

                    <h5 id="privacy-rights">YOUR RIGHTS REGARDING YOUR PERSONAL DATA</h5>

                    <p>
                        It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes. Subject to
                        limitations in applicable law, you may exercise specific rights you have to the personal data that we hold about you <br />
                        <br />
                        <strong>TO READ ABOUT OR EXERCISE YOUR SPECIFIC RIGHTS, PLEASE CLICK:</strong>
                        <ul>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF AUSTRALIA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF BRAZIL
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF CALIFORNIA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF CHINA
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF THE EUROPEAN UNION OR THE UNITED KINGDOM
                            </li>
                            <li>
                                <a href={pdfPrivacyThailand}>HERE</a> IF YOU ARE A RESIDENT OF THAILAND
                            </li>
                        </ul>
                        <strong>
                            TO EXERCISE YOUR RIGHTS OR IF YOU HAVE ANY CONCERNS ABOUT TYSON'S PROCESSING OF YOUR PERSONAL DATA, PLEASE CONTACT US AT E-MAIL{" "}
                            <a href="mailto:privacy@tyson.com">PRIVACY@TYSON.COM</a> OR AT
                        </strong>
                        <br />
                        <br />
                        Tyson Foods, Inc.
                        <br />
                        Privacy c/o Law Department
                        <br />
                        Mail Code CP006
                        <br />
                        2200 W. Don Tyson Parkway
                        <br />
                        Springdale, AR 72765-2020
                        <br />
                        <br />
                        ANY SUCH COMMUNICATION MUST BE IN WRITING.
                        <br />
                        <br />
                        We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal data that we
                        hold about you or make your requested changes. To verify your identity, we will generally match the identifying information provided in your request with
                        the information we have on file about you. Depending on the sensitivity of the personal information requested, we may also utilize more stringent
                        verification methods to verify your identity. We may ask you to provide other documentation to verify your identity. If this happens, we will reach out to
                        you directly with this request. We may not be able to comply with your request if we are unable to confirm your identity or connect the information you
                        submit in your request with personal information in our possession. Applicable law may allow or require us to refuse to provide you with access to some or
                        all of the personal data that we hold about you, or we may have destroyed, erased, or made your personal data anonymous in accordance with our data
                        retention obligations and practices. If we cannot provide you with access to your personal data, we will inform you of the reasons why, subject to any legal
                        or regulatory restrictions. If you choose to exercise your rights, we will respond as required by law. <br />
                        <br />
                        WHERE YOU ARE GIVEN THE OPTION TO SHARE YOUR PERSONAL DATA WITH US, YOU CAN ALWAYS CHOOSE NOT TO DO SO. IF YOU OBJECT TO THE PROCESSING OF YOUR PERSONAL
                        DATA, TYSON WILL RESPECT THAT CHOICE IN ACCORDANCE WITH ITS LEGAL OBLIGATIONS. THIS COULD MEAN THAT WE ARE UNABLE TO PERFORM THE ACTIONS NECESSARY TO
                        ACHIEVE THE PURPOSES OF PROCESSING DESCRIBED ABOVE, INCLUDING OFFERING OR CONTINUING AN EMPLOYMENT CONTRACT.
                    </p>

                    <h5>How to Contact Us</h5>

                    <p>
                        We have appointed a Data Protection Officer to oversee compliance with this Privacy Notice. If you have any questions about this Privacy Notice or how we
                        handle your personal data or would like to request access to your personal data, please contact the Data Protection Officer at{" "}
                        <a href="mailto:privacy@tyson.com">privacy@tyson.com</a>.
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
